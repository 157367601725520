import * as React from "react"
import HeroImage from "../components/HomePage/HeroImage"
import TestFlex from "../components/TestFlex"
import Test from "../components/Test"
import Layout from "../components/Layout"

function NewPage({ data }) {
  return (
    <Layout>
      <HeroImage />
      <TestFlex/>
      <Test/>
    </Layout>
    
  )
}

export default NewPage



