import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '../scss/HeroImage.scss';

export default function HeroImage() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulHeroImage(filter: { name: { eq: "Home Page Hero Image" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `);

  return (
    <div className="heroImageContainer">
      <StaticImage
        className={"heroImage"}
        src="https://images.ctfassets.net/x5jqyuhgi4rx/7m1lW4Bs2fxh9F0BFwDTQp/47a50d97b61bc8fa4b532ccedd26467b/heroimage.webp"
        alt={data.allContentfulHeroImage.edges[0].node.heroImage.altText}
        width={6000}
        height={4000}
        loading="lazy"
      />
    </div>
  );
}
