import React, { useEffect } from "react";
import "./scss/Test.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function FashionTechTextIntro() {
  const data = useStaticQuery(graphql`
  query MyQuery900 {
    allContentfulTextBlock(
        filter:{id:{eq: "17a421a9-06e4-52c0-ada3-6011088c3e5b"}}
    ) {
      edges {
        node {
          title
          bodyText {
            raw
          }
          id
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <div className="contentFeatureTest">
      <div className={'textContainerTest'}>
        <p className={'titleTest'}>{title}</p>
        <p className='bodyTest'>
          {documentToReactComponents(
            JSON.parse(bodyText.raw),
            options
          )}
        </p>
      </div>
    </div>
  );
}
